




















import moment from "moment";

import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { TopAppbar: () => import("@/views/layout/appbar.vue") },
  setup() {
    const isSuspended = ref(false);
    const isLoading = ref(false);
    const footerText = ref("©" + moment().format("YYYY") + "OneSIM 版權所有");

    return { isSuspended };
  }
});
