import Vue from "vue";
import app from "@/app.vue";
import CompositionAPI from "@vue/composition-api";
import lineClamp from "vue-line-clamp";

import router from "@/router";
import store from "@/store";

import * as moment from "moment";
import VueProgressBar from "vue-progressbar";

import "@fortawesome/fontawesome-free/css/all.css";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";

import "@/plugins/echarts";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, max, min, numeric, min_value } from "vee-validate/dist/rules";

// extend('required', {
// 	...required,
// 	message: 'This field is required'
// });

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

extend("min", {
  ...min,
  message: "{_field_} must be greater than {length} characters"
});

extend("min_value", {
  ...min_value,
  message: "{_field_} must be {min} or greater"
});

extend("email", {
  ...email,
  message: "Email must be valid"
});

extend("numeric", {
  ...numeric,
  message: "{_field_} may only contain numeric characters"
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.prototype.moment = moment;
moment.locale("zh-HK");

const options = {
  color: "#fe5d16",
  failedColor: "#fe5d16",
  thickness: "5px",
  transition: {
    speed: "0.1s",
    opacity: "0.5s",
    termination: 400
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

// Vue.use(VeeValidate);
Vue.use(VueProgressBar, options);
Vue.config.productionTip = false;
Vue.use(lineClamp, {
  // plugin options
});

Vue.use(CompositionAPI);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(app)
}).$mount("#app");
