import Vue from "vue";
import Echarts from "vue-echarts";

import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/candlestick";
import "echarts/lib/chart/tree";
import "echarts/lib/chart/treemap";
import "echarts/lib/chart/graph";

import "echarts/lib/component/dataZoom";
import "echarts/lib/component/graphic";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";

import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/visualMap";

Vue.component("chart", Echarts);
