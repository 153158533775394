/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import { RouteMeta } from "@/types";
// import { UserModule } from "@/store/modules/user";

// let requireAuth = function(to: any, from: any, next: any) {
//   // next();
//   if (!UserModule.isLoggedOn) {
//     next({
//       path: "/login",
//       query: { redirect: to.fullPath }
//     });
//   } else {
//     next();
//   }
// };

export default [
  {
    path: "*",
    redirect: {
      path: "/404"
    }
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/404.vue`
      )
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "routes" */
  //       `@/views/login.vue`
  //     )
  // },
  {
    path: "/",
    meta: {},
    name: "Root",
    redirect: {
      name: "home"
    }
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/home/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "home" })
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/about/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "about" })
  },
  {
    path: "/management",
    name: "management",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/management/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "management" })
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/services/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "services" })
  },
  {
    path: "/donation",
    name: "donation",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/donation/index.vue`
      ),
    meta: new RouteMeta({ title: "donation" })
  },
  {
    path: "/payment-tng",
    name: "payment-tng",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/paymentTng/index.vue`
      ),
    meta: new RouteMeta({ title: "tng" })
  },
  {
    path: "/payment-credit-card",
    name: "payment-credit-card",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/paymentCreditCard/index.vue`
      ),
    meta: new RouteMeta({ title: "credit-card" })
  },
  {
    path: "/projects",
    name: "projects",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/projects/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "projects" })
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "routes" */
        `@/views/components/privacyPolicy/index.vue`
      ),
    // beforeEnter: requireAuth,
    meta: new RouteMeta({ title: "privacy-policy" }),
    props: true
  }
];
